!<template>
  <div class="main_container">
    <div class="col-12 bread-crumbs">
      <span>Maitres d'ouvrage ></span>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Liste des maitres d'ouvrage
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <Notif :notif="notif" />
    </div>
    <div class="row mt-3">
      <div class="col-4">
        <button type="button"
                v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                class="btn btn-blue"
                data-toggle="modal"
                ref="modal_button"
                data-target="#exampleModal"
                @click="title='add'">Ajouter un maitre d'ouvrage</button>
      </div>
    </div>    
    <div class="inner_container">
      <table class="table table-striped"
             id="enterprise">
        <thead>
          <tr>
            <th class="th-blue">Nom entreprise</th>
            <th class="th-blue"></th>
          </tr>
        </thead>
        <tbody v-if="long==true && longueur > 0">
          <tr v-for="(prestataire,pkey) in prestataires.donnees"
              :key="pkey">
            <td scope="row">{{prestataire.libelle}}</td>
            <td class="icon-blue text-right">
              <i class="flaticon-pencil"  
                 v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                 @click="title='update',id_maitre=prestataire.id" 
                 ref="modal_button"
                 data-toggle="modal"
                 data-target="#exampleModal"></i>
              <i class="flaticon-delete ml-4"
                 v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                 @click="title='del',id_maitre=prestataire.id"
                 data-toggle="modal"
                 data-target="#exampleModal"></i>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="long==true && longueur == 0">
          <tr>
            <td scope="row"
                colspan="2"
                class="text-center">Aucune donnée enregistrée.</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td scope="row"
                colspan="2"
                class="text-center">Chargement en cours...</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='del'">Supprimer un maitre d'ouvrage</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='add'">Ajouter un maitre d'ouvrage</h5>
            <h5 class="modal-title"
                id="exampleModalLabel"
                v-if="title=='update'">Détail maitre d'ouvrage</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body"
               v-if="title=='add'">
            <AddMaitrise @added="added => (ajout = added)"/>
          </div>
          <div class="modal-body"
               v-if="title=='update'">
            <UpdateMaitrise :id_maitrise="id_maitre"
                            @updated="updated => (modifier = updated)"/>
          </div>
          <div class="modal-body text-center"
               v-if="title=='del'" >
            Voulez-vous vraiment supprimer ce maitre d'ouvrage?
          </div>
          <div class="modal-footer"
               v-if="title=='del'">
            <button type="button"
                    class="btn btn-secondary"
                    @click="destroyPrestataire()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
          <!-- affichage avenant -->
          
        </div>
      </div>
    </div>
  </div>
</template>
<style>
/* #enterprise tbody td,
#enterprise thead th{
  font-size: 0.48em;
} */
</style>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex"
import form from "vuejs-form"
import Notif from "@/components/shared/Toast"
import AddMaitrise from "./Add.vue"
import UpdateMaitrise from "./Update.vue"

export default {
  name:"Prestataires",
  components:{
    Notif,
    AddMaitrise,
    UpdateMaitrise
  },
  data:()=>({
    form:form({
      id:""
    }).rules({
      id:"required"
    }).messages({
      id:"Ce champs est requis."
    }),
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    long:false,
    longueur:0,
    title:"",
    user:"",
    ajout:false,
    modifier:false,
    id_maitre:""
  }),
  watch:{
    prestataires(){
      if(this.prestataires){
        this.long=true
        this.longueur=this.prestataires.donnees.length      
      }
    },
    ajout(){
      if(this.ajout){
        this.notif.message = "Maitre d'ouvrage créé avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setPrestataires("")
            this.getPrestataires()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    modifier(){
      if(this.modifier){
        this.notif.message = "Maitre d'ouvrage modifié avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setPrestataires("")
            this.getPrestataires()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgSuccessPrestataire(){
      if(this.msgSuccessPrestataire){
        this.notif.message = "Maitre d'ouvrage supprimé avec succès."
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setPrestataires("")
            this.getPrestataires()
            this.long=false
            this.longueur=0
            this.$refs["btn-close"].click()
          }.bind(this),
          3000
        ) 
      }
    },
    msgErrorPrestataire(){
      if(this.msgErrorPrestataire){
        this.notif.message = this.msgErrorPrestataire
        this.notif.type = "toast-danger"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.setMsgErrorPrestataire("")
          }.bind(this),
          3000
        ) 
      }
    }
  },
  created(){
    this.getPrestataires()
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
  },
  computed:{
    ...mapGetters(["prestataires","msgSuccessPrestataire","msgErrorPrestataire"])
  },
  methods:{
    ...mapActions(["getPrestataires","deletePrestataire"]),
    ...mapMutations(["setPrestataires","setMsgSuccessPrestataire","setMsgErrorPrestataire"]),
    destroyPrestataire(){
      this.form.id=this.id_maitre
      if (!this.form.validate().errors().any()) {
        this.deletePrestataire(this.form.data)
      }
    }
  }
}
</script>

